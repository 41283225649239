.starter-permium-pack-bg{
  background-color: #008AFF;
}

.gold-pack-bg{
  background: linear-gradient(180deg, #C27227 0%, #FEBC3D 28.67%);
  background-size: cover;
  background-repeat: inherit;
}

.starter-permium-pack-border{
  border: 3px solid #008AFF;
}

.gold-pack-border{
  border: 3px solid #DA8B14;
}

.navbar-dropdown-menu-badge{
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
}

.starter-pack-badge{
  background: rgba(0, 138, 255, 0.20);
  color: #AEDAFF;
}

.premium-pack-badge{
  background: rgba(0, 138, 255, 0.20);
  color: #AEDAFF;
  border: 1px solid #1192FF;
}

.gold-pack-badge{
  background: rgba(255, 184, 0, 0.20);
  border: 1px solid #DC8F19;
  color: #FFF2AE;
}


