.card-layout {
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-radius: 8px;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
}

.card-layout .chevron-hover{
  display: none;
}

.card-layout:hover .chevron-hover {
  display: flex;
}
