.rewards-main-layout {
  display: grid;
  grid-template-areas: 
    "overview overview"
    "chart spend"
    "benefits benefits";
  grid-template-rows: auto auto auto;
  grid-template-columns: 4fr 5fr;
  gap: 24px 24px;
}

.rewards-overview-layout{
  grid-area: overview;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.overview-border-friend-theme{
  border: 2px solid #50A6EF;
}

.overview-border-bestfriend-theme{
  border: 2px solid #7BC251;
}

.overview-border-soulmate-theme{
  border: 2px solid #F1C94B;
}

.overview-border-family-theme{
  border: 2px solid #FFA03B;
}

.overview-border-ambassador-theme{
  border: 2px solid #CB9E00;
}

.benefits-locked-tier-border {
  border: 1px solid var(--Fnpagnt);
}

.benefits-box-shadow-friend {
  box-shadow: 0px 0px 20px 0px #50A6EF;
}

.benefits-box-shadow-bestfriend {
  box-shadow: 0px 0px 20px 0px #7BC251;
}

.benefits-box-shadow-soulmate {
  box-shadow: 0px 0px 20px 0px #F1C94B;
}

.benefits-box-shadow-family {
  box-shadow: 0px 0px 20px 0px #FFA03B;
}


.overview-title{
  font-size: 20px;
  font-weight: 600;
}

.overview-points-count{
  color: #FFDC73;
  font-size: 34px;
  font-weight: 800;
}

.overview-current-tier{
  font-size: 34px;
  font-weight: 800;
}

.friend-tier-bg{
  background-color: #50A6EF;
}

.bestfriend-tier-bg{
  background-color: #7BC251;
}

.soulmate-tier-bg{
  background-color: #F1C94B;
}

.family-tier-bg{
  background-color: #FFA03B;
}

.overview-friend-tier{
  color: #50A6EF;
}

.overview-bestfriend-tier{
  color: #7BC251;
}

.overview-soulmate-tier{
  color: #F1C94B;
}

.overview-family-tier{
  color: #FFA03B;
}

.overview-ambassador-tier{
  background: var(--Theme-ambassador, linear-gradient(90deg, #CB9E00 0%, #EAD9B5 28.94%, #AB851D 98.1%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.overview-current-deliveries{
  font-size: 34px;
  font-weight: 800;
  line-height: 1;
}

.overview-target-deliveries{
  font-size: 24px;
  font-weight: 800;
}

.overview-divider{
  width: 1px;
  background-color: var(--bdgclInp);
  height: 80px;
}

.overview-expires-date{
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.rewards-chart-layout{
  grid-area: chart;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.doughnut-chart-container{
  width: 100%;
  height: 280px;
  max-width: 450px;
}

.doughnut-chart-remaining-deliveries{
  font-size: 16px;
  font-weight: 600;
}

.rewards-spend-points-layout{
  grid-area: spend;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.rewards-spend-points-secondary-title{
  font-size: 16px;
  font-weight: 400;
}

.rewards-spend-points-text{
  font-size: 14px;
  font-weight: 400;
}

.rewards-overview-title{
  font-size: 20px;
  font-weight: 600;
  text-align: start;
}

.rewards-overview-modal-text{
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: start;
}

.reward-modals{
  height: 70vh;
  overflow: auto;
}

.reward-modals::-webkit-scrollbar {
  width: 10px;
}

.reward-modals::-webkit-scrollbar-track { 
  border-radius: 9px;
  background-color: transparent;
}

.reward-modals::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--gcl); 
}

.rewards-howitworks-modal-steps{
  display: flex;
  align-items: center;
  gap: -16px;
}

.rewards-howitworks-modal-step-header{
  font-size: 24px;
  font-weight: 700;
}

.inline-divider{
  height: 2px;
  background-color: var(--fntClr);
  width: 120px;
}

.rewards-new-tier-modal-img-container{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 180px;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.rewards-new-tier-modal-img{
  width: 100%;
  height: 100%;
  aspect-ratio: 4/3;
}

.rewards-new-tier-card{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 12px;
  border-radius: 8px;
}

.rewards-tier-card-soulmate-bg{
  background-color: rgba(241, 201, 75, 0.08);
}

.rewards-tier-card-friend-bg{
  background-color: rgba(80, 166, 239, 0.08);
}

.rewards-tier-card-bestfriend-bg{
  background-color: rgba(123, 194, 81, 0.08);
}

.rewards-tier-card-family-bg{
  background-color: rgba(255, 160, 59, 0.08);
}

.rewards-tier-card-ambassador-bg{
  background-color: rgba(255, 160, 59, 0.08);
}

.rewards-benefits-layout {
  grid-area: benefits;
  padding: 14px;
  display: grid;
  grid-template-areas: 
    "tier tier-benefits"
    "benefits-progress benefits-progress"
  ;
  grid-template-rows: auto auto;
  grid-template-columns: 4fr 3fr;
  gap: 16px 16px;
};

.rewards-benefits-tier-layout {
  grid-area: tier;
}

.rewards-benefits-tier-benefits-layout {
  grid-area: tier-benefits;
}

.rewards-benefits-progress-layout {
  grid-area: benefits-progress;
}

.rewards-benefits-progress-bar {
  border-radius: 17px;
  height: 17px; 
  background: linear-gradient(90deg, #50A6EF 0%, #7BC251 35.5%, #F1C94B 63.46%, #FFA03B 100%);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  width: calc(100% - 60px);
}

.rewards-benefits-progress-tier {
  border-radius: 60px;
  height: 60px;
  width: 60px;
}

.rewards-benefits-your-tier-tooltip {
  top: -35px; 
  left: 50%; 
  transform: translateX(-50%);
}
.rewards-benefits-your-tier-tooltip-content {
  padding: 3px 8px; 
  font-size: 14px; 
  color: black; 
  font-weight: 700; 
  border-radius: 20px;
  margin-bottom: -2px;
}

@media only screen and (min-width: 860px) and (max-width: 1000px) {
  .overview-current-tier, .overview-points-count, .overview-current-deliveries {
    font-size: 26px;
    font-weight: 700;
  }

  .overview-current-deliveries{
    line-height: 1.1;
  }
}

@media only screen and (min-width: 770px) and (max-width: 860px) {

  .overview-divider{
    display: none;
  }

  .doughnut-chart-container{
    width: 280px;
    height: 270px;
  }

  .overview-current-tier, .overview-points-count, .overview-current-deliveries {
    font-size: 24px;
    font-weight: 700;
  }

  .overview-current-deliveries{
    line-height: 1.1;
  }
}

@media only screen and (min-width: 500px) and (max-width: 770px) {

  .overview-divider{
    display: none;
  }

  .rewards-main-layout{
    grid-template-columns: 1fr;
    grid-template-areas: 
    "overview"
    "chart"
    "spend"
    "benefits";
    max-width: 650px;
    gap: 24px 0;
    margin: 0 auto;
  }
  
  .overview-current-tier, .overview-points-count, .overview-current-deliveries {
    font-size: 22px;
    font-weight: 700;
  }

  .overview-current-deliveries{
    line-height: 1.1;
  }
}

@media only screen and (max-width: 500px) {

  .overview-divider{
    width: 100%;
    height: 1px;
  }

  .doughnut-chart-container{
    width: 360px;
    height: 300px;
  }

  .rewards-chart-layout{
    padding: 5px;
  }

  .rewards-main-layout{
    grid-template-columns: 370px;
    grid-template-areas: 
      "overview"
      "chart"
      "spend"
      "benefits";
    max-width: 370px;
    gap: 24px 0;
    margin: 0 auto;
  }

  .rewards-overview-layout{
    padding: 14px;
  }

  .rewards-spend-points-layout{
    padding: 14px;
  }

  .overview-current-tier, .overview-points-count, .overview-current-deliveries {
    font-size: 20px;
    font-weight: 600;
  }

  .overview-current-deliveries{
    line-height: 1.1;
  }
}

@media only screen and (max-width: 1200px) {
  .overview-current-tier, .overview-points-count, .overview-current-deliveries {
    font-size: 30px;
    font-weight: 700;
  }

  .overview-current-deliveries{
    line-height: 1.15;
  }
}






