.add-team-member-modal-header-text {
  font-size: 20px;
}

.add-staff-member-modal-divider{
  width: 100%;
  height: 1px;
  background-color: var(--gcl);
}

.add-staff-member-modal-main{
  min-width: fit-content;
  padding: 10px 40px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 40px;
}

.add-team-member-modal-section-header{
  font-size: 20px;
}

.add-team-member-modal-upload-text{
  font-size: 16px;
}

.add-team-member-modal-upload-image{
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  color: #40A4F9;
};
