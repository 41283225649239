.dashboard-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.dashboard-notifications-layout{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.dashboard-notifications-layout .card-layout {
  flex-basis: 300px;
}

.dashboard-card-title{
  font-size: 16px;
  font-weight: 700;
}

.rewards-card-info-layout {
  display: flex;
  align-items: center;
}

.rewards-info-title{
  font-size: 16px;
  font-weight: 400;
}

.rewards-info-text{
  font-size: 20px;
  font-weight: 700;
}

.rewards-card-progress-layout{
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.progress-bar-container{
  width: 100%;
  border-radius: 90px;
  height: 11px;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
}

.progress-bar{
  height: 100%;
  background-color: var(--bdgclInp);
}

.rewards-progress-text{
  font-size: 16px;
  font-weight: 400;
}

.custom-divider {
  width: 1px;
  background-color: var(--clrbrd);
  min-height: 80px;
}

.dashboard-delivery-rate-chart-wrapper{
  height: 180px;
  width: 250px;
  display: flex;
  align-items: center;
}

.delivery-ratio-text {
  font-size: 14px;
}

.delivered-ratio-circle{
  height: 6px;
  width: 6px;
  border-radius: 50px;
  background-color: #36D17E;
}

.canceled-ratio-circle{
  height: 6px;
  width: 6px;
  border-radius: 50px;
  background-color: #FBCE4D;
}

.dashboard-main-card-number{
  font-size: 28px;
  line-height: 1.1;
  letter-spacing: 0.3px;
  font-weight: 700;
}

.dashboard-payable-balance{
  color: #2FE1AC;
}

.dashboard-negative-amount{
  color: #F1C34B;
}

.dashboard-main-card-layout{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.dashboard-main-card-loading-item {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  padding: 8px;
}

.dashboard-main-card-title{
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.2px;
}

.dashboard-main-card-info-text{
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.1px;
}

.dashboard-main-card-item{
  border-radius: 4px;
  overflow: hidden;
  transition: opacity 500ms ease;
  min-width: 230px;
}

.dashboard-main-card-item:not(:nth-child(3)):hover{
  background-color: var(--bdgclInp);
  cursor: pointer;
}

.dashboard-main-card-item-arrow{
  opacity: 0;
  height: 30px; 
  width: 30px; 
  position: absolute; 
  top: 50%; 
  border-radius: 50%;  
  scale: 150%;
  z-index: 40;
  transition: opacity 500ms ease;
}

.slider-container:hover .dashboard-main-card-item-arrow {
  opacity: 1;
}

.left-arrow{
  left: -6px; 
  transform: translateY(30%);
  rotate: 180deg; 
}

.right-arrow{
  right: -6px;
  transform: translateY(-30%);
}

.dashboard-main-card-item-arrow:hover{
  cursor: pointer;
}

html[dir="rtl"] .left-arrow{
  right: -6px;
  left: auto;
}

html[dir="rtl"] .right-arrow{
  left: -6px;
  right: auto;
}

.dashboard-top-selling-product {
  color: #FFBE1E;
}

.order-delivery-chart-wrapper{
  max-width: 100%;
  max-height: 100%;
}

.rewards-card-loading-title{
  height: 11px;
  width: 150px;
  background: var(--bdgclInp);
  border-radius: 40px;
}

.rewards-card-loading-tier-svg{
  height: 30px;
  width: 30px;
  background: var(--bdgclInp);
  border-radius: 50%;
}

.rewards-card-loading-tier-name{
  height: 20px;
  width: 80px;
  background: var(--bdgclInp);
  border-radius: 40px;
}

.rewards-card-progress-text-loader {
  width: 200px;
  height: 13px;
  background: var(--bdgclInp);
  border-radius: 40px;
}

html[dir="rtl"] .deliveries-to-text{
  display: flex;
  flex-direction: row-reverse;
}

.dashboard-notification-card-loading-icon{
  width: 30px;
  height: 30px;
  background: var(--bdgclInp);
  border-radius: 4px;
}

.dashboard-notification-card-loading-text {
  width: 200px;
  height: 16px;
  background: var(--bdgclInp);
  border-radius: 40px;
}

.dashboard-main-card-loading-title{
  width: 200px;
  height: 16px;
  background: var(--bdgclInp);
  border-radius: 5px;
}

.dashboard-main-card-loading-text{
  height: 24px;
  width: 175px;
  background: var(--bdgclInp);
  border-radius: 5px;
}

.dashboard-main-card-loading-info{
  height: 12px;
  width: 200px;
  background: var(--bdgclInp);
  border-radius: 40px;
}

.dashboard-top-products-rank-skeleton{
  height: 25px;
  width: 25px;
  background: var(--bdgclInp);
  border-radius: 50%;
}

.dashboard-top-products-product-name-skeleton{
  height: 12px;
  width: 150px;
  background: var(--bdgclInp);
  border-radius: 40px;
}

.dashboard-top-products-orders-number-skeleton{
  height: 12px;
  width: 80px;
  background: var(--bdgclInp);
  border-radius: 40px;
}

.dashboard-top-products-quantity-skeleton{
  height: 12px;
  width: 40px;
  background: var(--bdgclInp);
  border-radius: 40px;
}

.confirmed-orders-card-text {
  font-size: 16px;
  font-weight: 600;
}

.confirmed-orders-card-number{
  font-size: 24px;
  font-weight: 700;
}

.dashboard-primary-notification {
  border-color: #145294 !important;
  background: rgba(29, 151, 254, 0.15);
}

.dashboard-primary-notification:hover {
  border-color: #1D97FE !important;
}

.dashboard-unfulfilled-orders-notification {
  border-color: #064E40 !important;
  background: rgba(6, 149, 114, 0.15);
}

.dashboard-unfulfilled-orders-notification:hover {
  border-color: #069572 !important;
}

.dashboard-alerted-orders-notification {
  background: rgba(231, 120, 0, 0.15);
  border-color: #7B320C !important;
}

.dashboard-alerted-orders-notification:hover{
  border-color: #E77800 !important;
}

@media screen and (max-width: 1355px) {

  .dashboard-main-card-layout {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 5px 0;
  }

  .dashboard-main-card-layout .custom-divider {
    display: none;
  }
}

@media screen and (max-width: 700px) {

  .dashboard-main-card-layout {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1px 1fr 1px 1fr 1px;
    gap: 5px;
  }

  .dashboard-main-card-layout .custom-divider {
    display: block;
    width: 100%;
    min-height: 1px;
  }
}