.custom-table-wrapper{
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}

.custom-table-header{
  background-color: var(--bdgclInp);
}

.custom-table-body{
  background-color: var(--defcl);
}

.custom-table-cell{
  padding: 10px;
  font-size: 14px;
  letter-spacing: 0.3px;
}

.custom-table-wrapper thead tr .custom-table-cell:first-child {
  background: linear-gradient(to right,var(--bdgclInp) 80%, transparent);
}

.custom-table-wrapper thead tr .custom-table-cell:last-child {
  background: linear-gradient(to left,var(--bdgclInp) 80%, transparent);
}

.custom-table-wrapper tbody tr .custom-table-cell:first-child {
  background: linear-gradient(to right,var(--defcl) 80%, transparent);
}

.custom-table-wrapper tbody tr .custom-table-cell:last-child {
  background: linear-gradient(to left,var(--defcl) 80%, transparent);
}

.custom-table-row:first-child .custom-table-cell{
  border-bottom: 1px solid gray;
}

.custom-table-row:not(:last-child) .custom-table-cell{
  border-bottom: 1px solid var(--bdgclInp);
}

.table-cell-loading-skeleton{
  height: 16px;
  width: 150px;
  background-color: var(--bdgclInp);
  border-radius: 40px;
}